<template>
  <div class="examine">
    <van-nav-bar :title="title" safe-area-inset-top fixed placeholder :z-index="999"/>
    <van-tabs v-model="active" color="#1989fa">
        <van-tab title="借款详情">
            <van-cell-group :border="false">
                <van-cell title="借款人" :value="loanData.userName" />
                <van-cell title="贷款类型" :value="loanType[loanData.type]"/>
                <van-cell title="借款金额" :value="loanData.applyAmount+'元'"/>
                <van-cell title="借款期限" :value="loanData.accountNum+'个月'"/>
                <van-cell title="借款利率" :value="rate+'%'"/>
                <van-cell title="总利息" :value="loanData.interestTotal+'元'"/>
                <van-cell title="流程状态" :value="actStatu(loanData.actStatus)" value-class="act_status" title-class="act_status"/>
            </van-cell-group>
            <div class="btn" v-if="taskId">
                <van-button type="danger" @click="back">不同意</van-button>
                <van-button type="info" @click="pass">同意</van-button>
            </div>
        </van-tab>

        <van-tab title="审批历史" class="exam_history">
            <van-steps direction="vertical" :active="0">
                <template v-for="item in historicFlow">
                    <van-step :key="item.id" v-if="item.endTime">
                        <h4>
                            <span>{{item.activityName}}</span>
                            <span>{{item.endTime}}</span>
                        </h4>
                        <p>[处理人]{{item.assigneeName}}</p>
                        <p v-if="item.comment">[审批意见]{{item.comment}}</p>
                    </van-step>
                </template>
            </van-steps>
        </van-tab>
    </van-tabs>

    <!-- 审核弹窗 -->
    <van-dialog 
        v-model="compObj.visible" 
        :title="compObj.title" 
        show-cancel-button
        confirmButtonColor="#1989fa"
        @cancel="compObj.visible=false,compObj.dealValue=''"
        @confirm="compHandler">
        <van-field label="审批意见" v-model="compObj.dealValue" rows="1" autosize type="textarea" placeholder="请输入意见"/>

        
    </van-dialog>

  </div>
</template>

<script>
import { 
    NavBar,
    Button, 
    Step, 
    Steps, 
    Field, 
    Dialog,
    Cell, 
    CellGroup,
    Toast,
    Tab, Tabs,
} from "vant";
import { 
    getLoanData,
    getRate,
    historyList,
    completeHandler,
    
    getProcessByActi,
    getTaskId
} from "@/api/api";
import {loanType} from '@/utils/customExpression'
import {mapMutations,mapGetters} from 'vuex';
export default {
    components: {
        [NavBar.name]:NavBar,
        [Button.name]:Button,
        [Step.name]:Step,
        [Steps.name]:Steps,
        [Field.name]:Field,
        [Cell.name]:Cell,
        [CellGroup.name]:CellGroup,
        [Tab.name]:Tab,
        [Tabs.name]:Tabs,
        [Dialog.Component.name]: Dialog.Component,
    },
    data() {
        return {
            active:0,
            title:'审核',
            loanData:{},
            historicFlow:[],
            loanType:loanType.type,//贷款类型
            rate:'',
            //审批
            compObj:{
                visible:false,
                dealValue:'',
                type:'pass',
                title:''
            },
            processData:{},
            taskId:'xx'
        }
    },
    computed: {
        // reStatue() {
        //     return this.taskId;
        //     // return ''
        // },
        ...mapGetters(['operateParams',
        // 'processData','taskId'
        ])
    },
    created() {
        console.log('=======Create',this.operateParams);
        this.operateParams.activitiId && this.getProc(this.operateParams.activitiId)
    },
    mounted() {
        //获取借款数据
        this.init();
    },
    watch:{
        taskId:{
            handler:function(val) {
                console.log('监听taskId状态',val);
                if(val) {
                    this.title="审核";
                }else {
                    this.title="查看";
                }
                this.initHistoryList();
            },
            immediate:true
        }
    },
    methods: {
        //状态(整个流程)
        actStatu(val) {
            return val==1?'进行中':val==2?'已结束':'未启动';
        },
        //借款信息
        init() {
            let operateParams = this.operateParams;
            let tableId = operateParams.tableId;
            let uid = operateParams.userId;
            // console.log('operateParams',operateParams);
            getLoanData({
                id: tableId,
                uid: uid
            }).then((res) => {
                // console.log(res);
                if(res.success) {
                    let resp = res.result;
                    this.initRate(resp.type,resp.accountNum);
                    this.loanData = res.result;
                }
            });
        },
        //利率
        initRate(type,mons) {
            getRate({
                type:type,
                mons:mons
            }).then(res => {
                if(res.success) {
                    this.rate = res.result;
                }
            })
        },
        //获取流程数据---编辑/查看/启动
        getProc(activitiId) {
            getProcessByActi(activitiId).then(res => {
                if(res.success) {
                    // this.Set_processData(res.result);
                    this.processData = res.result;
                    this.initTaskId(res.result.instanceId);
                }else {
                    Toast.fail(res.message);
                }
            })
        },
        //获取taskId
        initTaskId(instanceId) {
            getTaskId(instanceId).then(res => {
                if(res.success) {
                    let resp = res.result?res.result:'';
                    // this.SET_taskId(resp);
                    this.taskId = resp;
                }else {
                    Toast.fail(res.message);
                }
            })
        },
        // 审批历史
        initHistoryList() {
            if(this.processData.instanceId) {
                
                historyList({
                    processInstanceId:this.processData.instanceId
                }).then(resp => {
                    if(resp.code === 200) {
                        this.historicFlow = resp.rows.sort((a,b) => {return b.id - a.id;})
                    }
                })
            }
            
        },
        //通过 passTask
        pass() {
            this.compObj = {
                visible:true,
                type:'pass',
                title:'审批通过'
            }
        },
        //驳回
        back() {
            this.compObj = {
                visible:true,
                type:'back',
                title:'审批不通过'
            }
        },
        //审批操作
        compHandler() {
            let flag = this.compObj.type == 'pass';
            completeHandler({
                taskId:this.taskId,
                instanceId:this.processData.instanceId,
                variables:{
                    comment: this.compObj.dealValue,
                    pass:flag
                }
            }).then(res => {
                if(res.success) {
                    Toast.success('审核成功');
                    // this.SET_taskId('');
                    this.taskId = '';
                }else {
                    Toast.fail('审批失败,联系系统人员');
                }
            })
        },
        // ...mapMutations(['SET_taskId'])
    }
};
</script>

<style lang="less" scoped>
@nav-bar-background-color:#1890ff;
/deep/.van-nav-bar {
    background-color: @nav-bar-background-color;
}
/deep/.van-nav-bar__title {
    color:#fff;
    font-size: 16px;
}
/deep/.van-step--vertical{padding-top: 0;}
/deep/.van-step--vertical .van-step__circle-container{top:10px;}
/deep/.van-dialog__content {margin: 20px 10px;}
.act_status {color:#ff976a;}
.main {
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
    box-sizing: border-box;
}
.btn {
    text-align: center;
    margin: 40px auto 20px;
    display: flex;
    justify-content: center;
    button{margin-right: 10px;height: 36px;}
    /deep/.van-button--normal{
        padding: 0 24px;
    }
}
</style>
